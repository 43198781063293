.appointments .appointment-block {
	display: flex;
	position: absolute;
	z-index: 83;
	min-height: 58px;
	max-height: 58px;
	top: 0;
	left: 0;
	overflow: hidden;
}

	.appointments .appointment-block.appointment-block-sm {
		min-height: 35px;
		max-height: 35px;
	}

	.appointments .appointment-block.appointment-ping .travel,
	.appointments .appointment-block.appointment-ping .appointment {
		background: white !important;
	}


	.appointments .appointment-block.start-partial .btn:first-child {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 1px dashed #f8f8f8;
	}

	.appointments .appointment-block.end-partial .btn:last-of-type {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: 1px dashed #f8f8f8;
	}

	.appointments .appointment-block .travel {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		padding: 0;
		cursor: default;
	}

	.appointments .appointment-block .appointment {
		overflow-x: hidden;
		font-size: 12px;
		padding-top: 4px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		text-align: left;
		overflow: hidden;
		font-weight: bold;
		color: #333;
		line-height: .7rem;
		cursor: default;
	}

	.appointments .appointment-block .resize {
		min-width: 5px;
		min-height: 60px;
		cursor: ew-resize;
		position: absolute;
		z-index: 102;
		/*opacity: 0;*/
	}

.appointments.no-pointer-events,
.appointments.no-pointer-events div,
.blocked-time-slots.no-pointer-events,
.blocked-time-slots.no-pointer-events div {
	pointer-events: none;
}
