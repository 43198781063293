
.blocked-time-slots .blocked-time-slot {
	display: flex;
	position: absolute;
	z-index: 82;
	min-height: 58px;
	max-height: 58px;
	top: 0;
	left: 0;
	overflow: hidden;
	font-size: .8rem;
	line-height: .8rem;
	background-image: linear-gradient(to bottom,#6e6e6e 0,#424242 100%);
	border: 1px solid black;
	color: white;
	padding-top: 3px;
	padding-left: 3px;
}

	.blocked-time-slots .blocked-time-slot.blocked-time-slot-sm {
		min-height: 35px;
		max-height: 35px;
	}

	.blocked-time-slots .blocked-time-slot.start-partial {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 1px dashed #f8f8f8;
	}

	.blocked-time-slots .blocked-time-slot.end-partial {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: 1px dashed #f8f8f8;
	}
