
.mini-schedule-appointments .mini-schedule-appointment-block {
	display: flex;
	position: absolute;
	z-index: 80;
	min-height: 3px;
	max-height: 3px;
	top: 0;
	left: 0;
	overflow: hidden;
	pointer-events: none;
}

	.mini-schedule-appointments .mini-schedule-appointment-block .appointment {
		border-radius: 0;
		padding: 0;
	}

	.mini-schedule-appointments .mini-schedule-appointment-block .travel {
		border-radius: 0;
		padding: 0;
	}

	.mini-schedule-appointments .mini-schedule-appointment-block.start-partial .btn:first-child {
		border-left: 1px dashed #f8f8f8;
	}

	.mini-schedule-appointments .mini-schedule-appointment-block.end-partial .btn:last-of-type {
		border-right: 1px dashed #f8f8f8;
	}
