.error-wrapper {
    font-size: 12px;
    color: var(--bs-danger);
    margin-top: 2px;
    margin-left: 4px;
    display: flex; /* Use flexbox to align icon and text */
    align-items: center;  /* Vertically align icon and text */
}

.error-wrapper.absolute {
    position: absolute;
    display: block;
    border: 1px solid #fff3d1;
    background-color: #fffcee;
    border-radius: 4px;
    padding-top: 0;
}

.error-wrapper.show-icons::before {
    content: '\F332';
    font-family: 'bootstrap-icons';
    font-size: 16px;
    margin-right: 5px;
    position: relative;
    // vertical-align: top;
    vertical-align: middle; /* Align icon with the text */
}

@-moz-document url-prefix() {
    .error-wrapper.show-icons {
        background-position-y: 6px;
    }

    .error-wrapper.absolute.show-icons {
        background-position-y: 1px;
    }
}

.error-wrapper .error {
    margin-left: 0; /* Remove margin-left that pushes text too far */
    margin-top: 2px; /* Remove negative margin to ensure proper placement */
    // margin-left: 23px;
    vertical-align: top;
    font-size: 1.2em;
    text-align: left;
    // margin-top: -22px; 
}

.error-input {
    border: 1px solid var(--bs-danger);
}

.error-dropdown .slick-drop-down input {
    border: 1px solid var(--bs-danger) !important;
}

.error-search input {
    border: 1px solid var(--bs-danger);
}


