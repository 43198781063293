.mini-schedule-blocked-time-slots .mini-blocked-time-slot {
    display: flex;
    position: absolute;
    z-index: 80;
    min-height: 3px;
    max-height: 3px;
    top: 0;
    left: 0;
    overflow: hidden;
    background-image: linear-gradient(to bottom, #6e6e6e 0, #424242 100%);
    border: 1px solid black;
    pointer-events: none;
}

    .mini-schedule-blocked-time-slots .mini-blocked-time-slot.start-partial .btn:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 1px dashed #f8f8f8;
    }

    .mini-schedule-blocked-time-slots .mini-blocked-time-slot.end-partial .btn:last-of-type {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1px dashed #f8f8f8;
    }

    .mini-schedule-blocked-time-slots .mini-blocked-time-slot {
        border-radius: 0;
        padding: 0;
    }
