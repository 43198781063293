@import 'src/styles/variables.scss';

:root {
    font-family: 'Poppins', sans-serif;

    .width-one-fourth {
        min-width: 25%;
    }

    .width-one-third {
        min-width: 32%;
        max-width: 33%;
    }

    .width-half {
        min-width: 49%;
    }

    .width-full {
        min-width: 100%;
    }

    .bold-header{
        font-weight: bold;
        font-size: 20px;
        padding-top: 20px;
        padding-bottom: 5px;
      }

    .bi.bi-arrow-right-short,
    .bi.bi-arrow-left-short {
        -webkit-text-stroke-width: .70px;
        color: var(--bs-primary);
        background-color: rgba(var(--bs-primary-rgb), .1);
        padding: 0;
        border: 0;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button-main {
        background-color: var(--inherit);
        width: 150px;
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 14px 16px;
        color: var(--bs-gray-600);
    }

    .button-main.active {
        border: solid var(--bs-border-color);
        font-weight: bold;
        border-bottom: none;
        background-color: var(--bs-white);
        color: var(--bs-primary);
        border-radius: 7px 7px 0 0;
        height: 45px;
    }

    .loc-btn {
        background-color: white;
        color: black;
        border-radius: 20px;
        border: 2px solid var(--bs-border-color);
        padding: 5px;
        padding-right: 10px;
        padding-left: 10px;
        margin-left: 0px !important;
        margin-right: 8px !important;
    }

    .loc-btn.active {
        background-color: var(--bs-primary);
        color: white;
        border-radius: 20px;
    }

    .title {
        font-size: 25px;
        font-weight: bold;
        padding: 10px 0 10px 0 !important;
    }

    .schedule-top-bar .selected-date {
        width: 200px !important;
    }

    .slick-grid thead {
        border-bottom: 2px solid var(--bs-border-color) !important;
        color: black;
    }

    .slick-grid thead a {
        color: black;
    }

    .slick-file-list .slick-file-list-item .slick-file-list-item_img {
        text-align: center;
    }
    
    .slick-file-list .slick-file-list-item .slick-file-list-item_title .slick-file-list-item_display-title {
        margin-left: -19px;
    }
    
    .outline-card {
        border-left: 6px solid var(--bs-primary);
        border-top: 1px solid var(--bs-primary);
        border-right: 1px solid var(--bs-primary);
        border-bottom: 1px solid var(--bs-primary);
    }

    slick-tab-header i {
        font-size: 1.2em !important; /* Adjust the font size to your desired value */
        top: 0em !important;
    }
    //toggle slider
    .toggle-checkbox {
        position: relative;
        width: 40px;
        height: 20px;
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        border-radius: 15px;
        background-color: #ccc;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
    }

    .toggle-checkbox::before {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #fff;
        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }

    .toggle-checkbox:checked {
        background-color: #2196f3;
    }

    .toggle-checkbox:checked::before {
        transform: translateX(20px);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), 0 2px 10px rgba(0, 0, 0, 0.2);
    }

    .slick-tabs .slick-tab-header {
        padding-top: 6px
    }

    .slick-tabs .btn-primary {
        border-bottom: 2px solid var(--bs-primary) !important;
    }

    .slick-tabs .slick-tab {
        text-transform: uppercase;
        font-weight: bold;
        min-height: 40px;
        border: none;
    }

    .slick-file-drop {
        border: 1px dashed var(--bs-primary);
        color: var(--bs-primary);
        background-color: rgba(var(--bs-primary-rgb), 0.1);
    }
    
    .side-menu-background {
        background-color: #10253a;
        color: white;
    }

    .gray-line{
        border-bottom: 2px solid var(--bs-gray-500);
        flex-grow: 100;
        margin-top: 2px;
    }

    .pencil-btn {
        border: none;
        background-color: var(--bs-primary);
        color: white;
        border-radius: 0px;
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .plus-btn {
        border: none;
        background-color: var(--bs-primary);
        color: white;
        border-radius: 0px;
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .bn {
        border: none;
    }
    /* Scrollbar */

    textarea::-webkit-scrollbar{
        width: auto !important;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background: #eee;
    }

    ::-webkit-scrollbar-thumb {
        background: gray;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .hide-scroll-bar {
        ::-webkit-scrollbar {
            height: 8px !important;
            width: 0px !important;
            border: 1px solid #fff !important;
        }

        ::-webkit-scrollbar-track {
            border-radius: 0 !important;
            background: #eeeeee !important;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 0 !important;
            background: #b0b0b0 !important;
        }
    }


    .slick-drop-down {
        min-width: 70px !important;
    }

    .new-button {
        border-radius: 19px;
        min-width: 80px;
    }

    //bootstrap 5 added underline on links so im taking those off here
    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    //bootstrap 5 added borders between columns on striped tables which looked weird
    .table-striped.table-bordered {
        border: 1px solid var(--bs-border-color); /* Default Bootstrap border color */
    }

    .table-striped.table-bordered tbody td {
        border-right: none;
        border-left: none;
    }

    .slick-dialog .slick-dialog_header .header-left {
        padding-left: 7px !important;
    }

    //remove when slick gets released 
    .slick-photo-gallery .slick-photo-gallery_content {
        background-color: var(--bs-body-bg);
    }

    .modal-footer {
        border-top:none;
    }


    .btn-rounded{
        border-radius: 20px;
        margin-left: 7px;
    }

    .red{
        color: var(--bs-danger);
    }


    //ACCOUNTING PAGES 

    .accepted-status {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 3px;
        margin-bottom: 8px;
    }

    .border-money{
        border: 2px solid var(--bs-gray-200);
        padding: 5px;
        border-radius: 5px;
        background-color: var(--bs-gray-200);
    }
    span.accepted {
        border: 1px solid var(--bs-success);
        background: var(--bs-success);
        padding: 3px;
        color: white;
        border-radius: 5px;
    }
    
    span.not-accepted {
        border: 1px solid orange;
        background: orange;
        padding: 3px;
        color: white;
        border-radius: 5px;
    }

    span.void {
        border: 1px solid var(--bs-danger);
        background: var(--bs-danger);
        padding: 3px;
        color: white;
        border-radius: 5px;
    }

    .card-arrow{
        display: flex;
        align-items: center; 
        color: var(--bs-secondary);
    }

    .purple{
        color: var(--bs-purple)
    }
}
